@import "../../_variables"


.settings__header
  border-radius 6px 6px 0 0
  background-color grayLightest

.settings__fieldset
  margin-bottom 20px

.settings__buttons
  margin-top 30px