// appearance animation
//
@keyframes notification
  0%
    transform rotateY(-90deg)
    opacity 0
  70%
    transform rotateY(20deg)
    opacity .8
  90%
    transform rotateY(-10deg)
    opacity 1
  100%
    transform rotateY(-0deg)
    opacity: 1

// notifications container
//
.notifications
  position fixed
  right 10px
  top 10px
  z-index 9999

  // generic notice styles & animation
  //
  & > .alert
    position: relative;
    margin 5px 0px

    max-width 300px
    max-height (alert_notice_max_height=200px)
    overflow auto
    text-shadow none

    transition all .5s linear

    // OFF state
    &.fade
      opacity 0
      // height animation hack
      max-height 0
      overflow hidden

      // ON state
      &.in
        opacity .95
        animation notification .75s linear
        // just return original value, if we use fade
        overflow auto
        max-height alert_notice_max_height

    & a
      font-weight 700
      text-decoration underline
