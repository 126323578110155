@import "../../_variables"


#names-editor
  overflow hidden
  padding-bottom 10px // to show shadow of bottom row elements

#preview
  padding-bottom 10px // to show shadow of bottom row elements


.names-editor__m-drag-started
  .preview-glyph-drop
    pointer-events none // to correctly handle 'dragleave' event while sorting
  .preview-glyph-drop.preview-glyph-drop__m-current
    pointer-events auto


.preview-glyph
  line-height 1.5em
  height 1.5em
  white-space nowrap
  background-color white
  position relative

  .icon
    display inline-block
    min-width 1em
    text-align center
    margin-right 0.5em
    cursor move

  .prefix
  .suffix
    cursor default

  .suffix
    display none

  &:hover .glyph-name
    background-color lighten(yellow, 70%)
    //box-shadow 0 0 0px 2px rgba(168, 82, 82, 0.6) inset
    border-radius 4px
    cursor pointer

  .glyph-name
    transition background-color .3s ease-in-out
    display inline-block
    box-shadow none
    border 0
    padding-top 0
    padding-bottom 2px
    width 150px
    font-size inherit
    vertical-align baseline
    background-color darken(white, 2%)
    border-radius 2px

    &:focus
      outline none
      box-shadow 0 0 8px rgba(168, 82, 82, 0.6), 0 0 0 2px rgba(168, 82, 82, 0.6)
      background-color lighten(rgb(168, 82, 82), 95%)
      border-radius 4px
      position relative
      z-index 1000
      cursor auto

  .remove
    position absolute
    top 2px
    right 10px
    transition all .3s ease-in-out
    opacity 0
  &:hover .remove
    opacity 1


// For drag & drop - disable standard appearance
.preview-glyph__m-hovered
  // background inherit
  left 15px
  border-left 3px solid #d9534f

.preview-glyph-drop__m-current
  opacity .4
  .glyph-name
    background-color darken(white, 2%) !important
  .remove
    opacity 0 !important

// Toggle visual state for suffix-based CSS style
//
.css-use-suffix
  .prefix
    display none
  .suffix
    display inline
  .glyph-name
    text-align right
