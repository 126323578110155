@import "../../_variables"


.toolbar-container
  position relative
  background-color #fcfcfc
  padding-top 10px
  padding-bottom 10px
  margin-bottom 10px
  border-bottom 1px solid #eee;

.btn-toolbar
  margin 0

.toolbar__size-slider
  width 150px !important
  display inline-block
  float left
  // return common view
  box-shadow none !important
  border-color transparent !important
  background-color transparent

.toolbar__size-slider
  // Chrome ignode left/righ paddings.
  // So, reset defaults to use margins from next element
  padding-right 0px
  padding-left 0px
  min-height 34px

.toolbar__size-indicator
  display inline-block
  float  left
  line-height 34px
  margin 0 1em 0 0.5em
  color #666
  font-size 11px

//
// Search tweak
//

.toolbar__search
  border-radius 20px

.search-permalink
  padding-left 4px
  padding-right 4px

//
// Settings menu
//
.toolbar__settings-menu
  min-width 200px
  .checkbox
    margin-top 5px
    input
      margin-right 5px
      vertical-align text-bottom
  label
    margin-bottom 2px
    font-weight normal
