#io-progress
  position fixed
  top 50px
  left 50%
  z-index 2000

  padding 3px 20px 3px 15px
  background-color #fcf8e3
  border 1px solid (#fcf8e3 - 50%)
  font-size 12px
  color black
  width 140px
  margin-left -70px
  box-sizing border-box

  display none

  > .close
    font-size 18px
    top 0
    right -15px
    //margin-left 10px
