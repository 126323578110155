@import "../../_variables"


.result-glyph
  float left
  width 64px
  margin 0 10px 10px 0
  color #fff

  background-color grayDark
  border-left 1px solid grayDark
  border-right 1px solid grayDark
  border-radius 6px

  box-shadow: 0 0 1px grayLight

  .top
    position relative
    font-size 14px
    input
      width 25px
      text-align center

  .remove
    position absolute
    top 4px
    right -2px
    color grayLighter
    transition all .3s ease-in-out
    opacity 0
  &:hover .remove
    opacity 1

  .center
    height 64px
    line-height 64px
    text-align center
    background-color white
    color #444
    font-size 24px

  .bottom
    font-size 10px
    input
      width 45px


  .top, .bottom
    text-align center
    line-height 16px

    input
      transition all .3s ease-in-out
      border 1px solid grayLight
      border-radius 3px
      background-color grayDark
      color grayLighter
      box-shadow 0 0 1px gray

  //&.mapping-matched
  //  border-color #444
  //  background-color #444

  .code-prefix, .code-editable, .char-editable
    display inline-block
    margin 3px 0
  .char-editable
    padding 0 3px


  &:hover
    .top, .bottom
      input
        background-color gray
        color white
        cursor pointer

  .top, .bottom
    input:focus
      background-color grayLighter
      color grayDark
      cursor auto
