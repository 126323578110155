html {
  overflow-y: scroll;
}
.xselectable-box {
  border: 1px dotted #000;
  position: absolute;
  z-index: 100;
}
.animate-spin {
  animation: spin 2s infinite linear;
  display: inline-block;
}
.animate-spin:before {
  font-size: inherit !important;
  text-shadow: none;
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.result-glyph {
  float: left;
  width: 64px;
  margin: 0 10px 10px 0;
  color: #fff;
  background-color: #444;
  border-left: 1px solid #444;
  border-right: 1px solid #444;
  border-radius: 6px;
  box-shadow: 0 0 1px #999;
}
.result-glyph .top {
  position: relative;
  font-size: 14px;
}
.result-glyph .top input {
  width: 25px;
  text-align: center;
}
.result-glyph .remove {
  position: absolute;
  top: 4px;
  right: -2px;
  color: #ccc;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
.result-glyph:hover .remove {
  opacity: 1;
}
.result-glyph .center {
  height: 64px;
  line-height: 64px;
  text-align: center;
  background-color: #fff;
  color: #444;
  font-size: 24px;
}
.result-glyph .bottom {
  font-size: 10px;
}
.result-glyph .bottom input {
  width: 45px;
}
.result-glyph .top,
.result-glyph .bottom {
  text-align: center;
  line-height: 16px;
}
.result-glyph .top input,
.result-glyph .bottom input {
  transition: all 0.3s ease-in-out;
  border: 1px solid #999;
  border-radius: 3px;
  background-color: #444;
  color: #ccc;
  box-shadow: 0 0 1px #666;
}
.result-glyph .code-prefix,
.result-glyph .code-editable,
.result-glyph .char-editable {
  display: inline-block;
  margin: 3px 0;
}
.result-glyph .char-editable {
  padding: 0 3px;
}
.result-glyph:hover .top input,
.result-glyph:hover .bottom input {
  background-color: #666;
  color: #fff;
  cursor: pointer;
}
.result-glyph .top input:focus,
.result-glyph .bottom input:focus {
  background-color: #ccc;
  color: #444;
  cursor: auto;
}
.glyph-dialog__header {
  border-radius: 6px 6px 0 0;
  background-color: #eee;
}
.glyph-dialog__fieldset {
  margin-bottom: 20px;
}
.glyph-dialog__buttons {
  margin-top: 30px;
}
.glyph-dialog__keywords {
  width: 33.3333% !important;
}
.glyph-dialog__char-xl {
  font-size: 60px;
  margin-right: 20px;
}
.glyph-dialog__char-md {
  font-size: 40px;
  margin-right: 20px;
}
.glyph-dialog__char-sm {
  font-size: 20px;
}
#names-editor {
  overflow: hidden;
  padding-bottom: 10px;
}
#preview {
  padding-bottom: 10px;
}
.names-editor__m-drag-started .preview-glyph-drop {
  pointer-events: none;
}
.names-editor__m-drag-started .preview-glyph-drop.preview-glyph-drop__m-current {
  pointer-events: auto;
}
.preview-glyph {
  line-height: 1.5em;
  height: 1.5em;
  white-space: nowrap;
  background-color: #fff;
  position: relative;
}
.preview-glyph .icon {
  display: inline-block;
  min-width: 1em;
  text-align: center;
  margin-right: 0.5em;
  cursor: move;
}
.preview-glyph .prefix,
.preview-glyph .suffix {
  cursor: default;
}
.preview-glyph .suffix {
  display: none;
}
.preview-glyph:hover .glyph-name {
  background-color: #ffffb3;
  border-radius: 4px;
  cursor: pointer;
}
.preview-glyph .glyph-name {
  transition: background-color 0.3s ease-in-out;
  display: inline-block;
  box-shadow: none;
  border: 0;
  padding-top: 0;
  padding-bottom: 2px;
  width: 150px;
  font-size: inherit;
  vertical-align: baseline;
  background-color: #fafafa;
  border-radius: 2px;
}
.preview-glyph .glyph-name:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(168,82,82,0.6), 0 0 0 2px rgba(168,82,82,0.6);
  background-color: #fbf6f6;
  border-radius: 4px;
  position: relative;
  z-index: 1000;
  cursor: auto;
}
.preview-glyph .remove {
  position: absolute;
  top: 2px;
  right: 10px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
.preview-glyph:hover .remove {
  opacity: 1;
}
.preview-glyph__m-hovered {
  left: 15px;
  border-left: 3px solid #d9534f;
}
.preview-glyph-drop__m-current {
  opacity: 0.4;
}
.preview-glyph-drop__m-current .glyph-name {
  background-color: #fafafa !important;
}
.preview-glyph-drop__m-current .remove {
  opacity: 0 !important;
}
.css-use-suffix .prefix {
  display: none;
}
.css-use-suffix .suffix {
  display: inline;
}
.css-use-suffix .glyph-name {
  text-align: right;
}
.tab-content {
  overflow: visible;
}
#selector {
  position: relative;
}
.selector__font-header {
  margin-top: 0px;
}
small.selector__font-details {
  color: #999;
  transition: opacity 0.3s ease;
  margin-left: 1em;
  margin-right: 1em;
  font-size: 14px;
}
a.selector__font-link {
  color: #999;
  margin-left: 0.5em;
}
a.selector__font-link:before {
  margin-right: 0;
  width: auto;
}
a.selector__font-control {
  color: #999;
}
a.selector__font-name {
  transition: opacity 0.3s ease;
  color: inherit;
  text-decoration: none;
  outline: 0 none;
}
.selector__font-name:after {
  transition: opacity 0.3s ease;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #333;
  content: "";
  display: inline-block;
  height: 0;
  width: 0;
  margin-left: 10px;
  vertical-align: middle;
  margin-left: 8px;
  opacity: 0;
}
.selector__font-header:hover .selector__font-name:after {
  opacity: 1;
}
._collapsed .selector__font-header {
  font-size: 18px;
  line-height: 24px;
}
._collapsed .selector__font-header .selector__font-name,
._collapsed .selector__font-header .selector__font-details {
  opacity: 0.2;
}
._collapsed .selector__font-header:hover .selector__font-name,
._collapsed .selector__font-header:hover .selector__font-details {
  opacity: 0.7;
}
._collapsed .selector__font-name:after {
  border-bottom: 0 none;
  border-top: 3px solid #333;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  margin-left: 5px;
}
._collapsed .selector__glyphs-container {
  display: none;
}
.selector__font-cnt {
  position: absolute;
  left: -10em;
  width: 10em;
  text-align: right;
}
.selector__font-cnt-selected,
.selector__font-cnt-search {
  font-size: 14px;
  color: #f00;
  margin-right: 0.5em;
  opacity: 0.6;
}
.selector__font-cnt-search {
  color: #008000;
  display: none;
}
.search-mode .selector__font-cnt-selected {
  display: none;
}
.search-mode .selector__font-cnt-search {
  display: inline;
}
.selector__glyphs-container {
  margin-bottom: 20px;
  margin-left: 10px;
}
.selector__glyphs-list {
  display: inline-block;
  padding: 0;
  user-select: none;
}
.selector__glyph {
  position: relative;
  border-radius: 30px;
  float: left;
  margin: 0 8px 8px 0;
  width: 48px;
  height: 48px;
  list-style: none;
}
.selector__glyph-image {
  line-height: 48px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .selector__glyph-image {
    line-height: 49.5px;
  }
}
.selector__glyph-delete {
  position: absolute;
  right: -10px;
  top: -5px;
  display: none;
  width: 20px;
  height: 20px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  border-radius: 20px;
  background-color: #f8f8f8;
  box-shadow: 0 0 5px 2px #f8f8f8;
}
.selector__glyph-delete:before {
  width: auto;
  margin: 0;
}
.selector__glyph-delete:hover {
  display: block;
}
.selector__glyph:hover .selector__glyph-delete {
  display: block;
}
.selector__glyph-inner {
  font-weight: normal;
  border-radius: 30px;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-size: inherit;
  text-align: center;
}
.selector__glyph-inner:before {
  position: absolute;
  content: '';
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 30px;
  border: 2px solid transparent;
}
.selector__glyph-inner:hover,
.selector__glyph:focus .selector__glyph-inner {
  background: #333;
  color: #fff;
}
.selector__glyph.xselectable-selected .selector__glyph-inner {
  background-color: #efe;
}
.selector__glyph.xselectable-selected .selector__glyph-inner:before {
  border-color: #8d8;
}
.selector__glyph.xselectable-selected .selector__glyph-inner:hover {
  color: inherit;
}
.selector__glyph .selector__glyph-inner.checked {
  background-color: #fffdfd;
}
.selector__glyph .selector__glyph-inner.checked:before {
  border-color: #d88;
}
.selector__glyph.xselectable-selected .selector__glyph-inner.checked {
  background-color: #efe;
}
.selector__glyph .selector__glyph-inner.checked:hover,
.selector__glyph:focus .selector__glyph-inner.checked {
  background: #666;
}
.selector__glyph.xselectable-selected .selector__glyph-inner.checkedbefore:border-color #666,
.selector__glyph.xselectable-selected .selector__glyph-inner.checked:hover {
  color: inherit;
}
.selector__glyph .animate-spin {
  animation: spin 2s 3 linear;
  display: inline-block;
}
.selector__glyph:hover .animate-spin {
  animation-iteration-count: infinite;
}
.selector__search-not-found {
  display: none;
  font-size: 14px;
}
.search-mode .selector__search-not-found {
  display: block;
}
.selector__custom-helper {
  font-size: 14px;
  margin-bottom: 30px;
  padding: 15px;
  border-radius: 3px;
  background-color: #f8f8f8;
  text-align: center;
}
._multicursor,
._multicursor .selector__glyph label,
.xselectable-box {
  cursor: crosshair;
}
.settings__header {
  border-radius: 6px 6px 0 0;
  background-color: #eee;
}
.settings__fieldset {
  margin-bottom: 20px;
}
.settings__buttons {
  margin-top: 30px;
}
.tabs-container {
  position: relative;
}
.nav-tabs {
  margin-bottom: 0;
  border-bottom: 1px solid #aaa;
}
.nav-tabs .nav-link {
  cursor: pointer;
}
.nav-tabs .disabled,
.nav-tabs .disabled:hover {
  opacity: 0.3;
  cursor: default;
}
.nav-tabs > .nav-item > .nav-link {
  outline: none;
}
.nav-tabs > .nav-item > .nav-link.active,
.nav-tabs > .nav-item > .nav-link.active:hover {
  font-weight: bold;
  border-color: #aaa #aaa transparent !important;
  box-shadow: -2px -2px 2px -2px #ccc, 2px -2px 2px -2px #ccc;
}
.stats {
  text-align: center;
  font-size: 10px;
  color: #888;
  position: absolute;
  right: 0;
  bottom: 0;
}
.toolbar-container {
  position: relative;
  background-color: #fcfcfc;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.btn-toolbar {
  margin: 0;
}
.toolbar__size-slider {
  width: 150px !important;
  display: inline-block;
  float: left;
  box-shadow: none !important;
  border-color: transparent !important;
  background-color: transparent;
}
.toolbar__size-slider {
  padding-right: 0px;
  padding-left: 0px;
  min-height: 34px;
}
.toolbar__size-indicator {
  display: inline-block;
  float: left;
  line-height: 34px;
  margin: 0 1em 0 0.5em;
  color: #666;
  font-size: 11px;
}
.toolbar__search {
  border-radius: 20px;
}
.search-permalink {
  padding-left: 4px;
  padding-right: 4px;
}
.toolbar__settings-menu {
  min-width: 200px;
}
.toolbar__settings-menu .checkbox {
  margin-top: 5px;
}
.toolbar__settings-menu .checkbox input {
  margin-right: 5px;
  vertical-align: text-bottom;
}
.toolbar__settings-menu label {
  margin-bottom: 2px;
  font-weight: normal;
}
body {
  padding-top: 180px;
  padding-bottom: 30px;
  min-width: 700px;
}
.layout__fixed {
  min-width: 700px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 3px 5px #fff;
  z-index: 10;
}
.layout__fixed .layout__navbar .container-fluid {
  position: relative;
}
.layout__fixed .notifications {
  top: 160px;
  position: absolute;
}
.layout__navbar {
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  margin-bottom: 0;
}
.drop-progress {
  background-color: #ffd;
}
iframe.twitter-follow-button,
iframe.twitter-share-button {
  margin-left: 10px;
  margin-top: 7px;
}
.gplus > div {
  top: 9px;
  position: relative;
}
.navbar-nav .twitter-follow-button,
.navbar-nav .twitter-share-button,
.navbar-nav .gplus,
.navbar-nav .FlattrButton {
  transition: opacity 0.5s ease;
  opacity: 0.6;
}
.navbar-nav:hover .twitter-follow-button,
.navbar-nav:hover .twitter-share-button,
.navbar-nav:hover .gplus,
.navbar-nav:hover .FlattrButton {
  opacity: 1;
}
input[type="file"].invisible {
  position: absolute;
  left: -10000px;
}
.nojs,
.nocookies {
  display: none;
  left: 50%;
  width: 500px;
  margin-left: -250px;
  text-align: center;
  position: absolute;
  top: 90px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4em;
  z-index: 1000;
}
.no-js .nojs,
.no-cookies .nocookies {
  display: block;
}
p.note {
  color: #888;
}
#io-progress {
  position: fixed;
  top: 50px;
  left: 50%;
  z-index: 2000;
  padding: 3px 20px 3px 15px;
  background-color: #fcf8e3;
  border: 1px solid #d8b917;
  font-size: 12px;
  color: #000;
  width: 140px;
  margin-left: -70px;
  box-sizing: border-box;
  display: none;
}
#io-progress > .close {
  font-size: 18px;
  top: 0;
  right: -15px;
}
.notifications {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 9999;
}
.notifications > .alert {
  position: relative;
  margin: 5px 0px;
  max-width: 300px;
  max-height: 200px;
  overflow: auto;
  text-shadow: none;
  transition: all 0.5s linear;
}
.notifications > .alert.fade {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}
.notifications > .alert.fade.in {
  opacity: 0.95;
  animation: notification 0.75s linear;
  overflow: auto;
  max-height: 200px;
}
.notifications > .alert a {
  font-weight: 700;
  text-decoration: underline;
}
@-moz-keyframes notification {
  0% {
    transform: rotateY(-90deg);
    opacity: 0;
  }
  70% {
    transform: rotateY(20deg);
    opacity: 0.8;
  }
  90% {
    transform: rotateY(-10deg);
    opacity: 1;
  }
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}
@-webkit-keyframes notification {
  0% {
    transform: rotateY(-90deg);
    opacity: 0;
  }
  70% {
    transform: rotateY(20deg);
    opacity: 0.8;
  }
  90% {
    transform: rotateY(-10deg);
    opacity: 1;
  }
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}
@-o-keyframes notification {
  0% {
    transform: rotateY(-90deg);
    opacity: 0;
  }
  70% {
    transform: rotateY(20deg);
    opacity: 0.8;
  }
  90% {
    transform: rotateY(-10deg);
    opacity: 1;
  }
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}
@keyframes notification {
  0% {
    transform: rotateY(-90deg);
    opacity: 0;
  }
  70% {
    transform: rotateY(20deg);
    opacity: 0.8;
  }
  90% {
    transform: rotateY(-10deg);
    opacity: 1;
  }
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}
