@import "../../_variables"


.glyph-dialog__header
  border-radius 6px 6px 0 0
  background-color grayLightest

.glyph-dialog__fieldset
  margin-bottom 20px

.glyph-dialog__buttons
  margin-top 30px

.glyph-dialog__keywords
  width 33.3333% !important

.glyph-dialog__char-xl
  font-size 60px
  margin-right 20px
.glyph-dialog__char-md
  font-size 40px
  margin-right 20px
.glyph-dialog__char-sm
  font-size 20px
