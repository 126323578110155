@import "../../_variables"

.tabs-container
  position relative

//=== Fix tab styles
.nav-tabs
  margin-bottom 0
  border-bottom: 1px solid #aaa

.nav-tabs .nav-link
  cursor pointer

.nav-tabs .disabled
.nav-tabs .disabled:hover
  opacity 0.3
  cursor default

.nav-tabs > .nav-item > .nav-link
  outline: none
  //transition background-color .5s ease-in-out

.nav-tabs > .nav-item > .nav-link.active,
.nav-tabs > .nav-item > .nav-link.active:hover
  font-weight: bold;
  border-color #aaa #aaa transparent !important
  box-shadow -2px -2px 2px -2px #ccc, 2px -2px 2px -2px #ccc

//====== users counter ============
.stats
  text-align center
  font-size 10px
  color #888
  position absolute
  right 0
  bottom 0
