@import "../_variables"


html
  overflow-y scroll // remove glitch on step 3, when scroller disappears


.xselectable-box
  border 1px dotted black
  position absolute
  z-index 100


// Animation for spinners
//
@keyframes spin
  from
    -moz-transform rotate(0deg)
    -o-transform rotate(0deg)
    -webkit-transform rotate(0deg)
    transform rotate(0deg)
  to
    -moz-transform rotate(359deg)
    -o-transform rotate(359deg)
    -webkit-transform rotate(359deg)
    transform rotate(359deg)

// ! `:before` animation is not supported well
// We have to rotate parent, and it should be block/inline-block

.animate-spin
  animation spin 2s infinite linear
  display inline-block
  &:before
    font-size inherit !important
    text-shadow none