@import "../../_variables"

//
// Override bootstrap's default, to show counter tickers
//
.tab-content
  overflow visible



#selector
  position relative

////////////////////////////////////////////////////////////////////////////////
// Font header content

.selector__font-header
  margin-top 0px

small.selector__font-details
  color #999
  transition opacity .3s ease
  margin-left 1em
  margin-right 1em
  font-size 14px

a.selector__font-link
  color grayLight
  margin-left .5em
  &:before
    margin-right 0
    width auto

a.selector__font-control
  color grayLight

a.selector__font-name
  transition opacity .3s ease
  color inherit
  text-decoration none
  outline 0 none

// collapser & animations on state/hover change

.selector__font-name:after
  transition opacity .3s ease
  border-left 6px solid transparent
  border-right 6px solid transparent
  border-bottom 6px solid #333
  content ""
  display inline-block
  height 0
  width 0
  margin-left 10px
  vertical-align middle
  margin-left 8px
  opacity 0

.selector__font-header:hover .selector__font-name:after
  opacity 1


._collapsed

  .selector__font-header
    font-size 18px
    line-height 24px

    .selector__font-name
    .selector__font-details
      opacity .2

    &:hover
      .selector__font-name
      .selector__font-details
        opacity .7

  .selector__font-name:after
    border-bottom 0 none
    border-top 3px solid #333
    border-left 3px solid transparent
    border-right 3px solid transparent
    margin-left 5px

  .selector__glyphs-container
    display none

// Counters near font name

.selector__font-cnt
  position absolute
  left -10em
  width 10em
  text-align right

.selector__font-cnt-selected
.selector__font-cnt-search
  font-size 14px
  color red
  margin-right .5em
  opacity .6

.selector__font-cnt-search
  color green
  display none

.search-mode
  .selector__font-cnt-selected
    display none
  .selector__font-cnt-search
    display inline

////////////////////////////////////////////////////////////////////////////////
// glyphs

.selector__glyphs-container
  margin-bottom 20px
  margin-left 10px

.selector__glyphs-list
  display inline-block
  padding 0
  user-select none


.selector__glyph
  position relative
  border-radius 30px
  float left
  margin 0 8px 8px 0
  width 48px 
  height 48px
  list-style none
.selector__glyph-image
  line-height 48px
// Chrome hack - it has different icon alligment, no ideas why
@media screen and (-webkit-min-device-pixel-ratio:0)
  .selector__glyph-image
    line-height 49.5px


.selector__glyph-delete
  position absolute
  right -10px
  top -5px
  display none
  width 20px
  height 20px
  font-size 16px
  line-height 20px
  text-align center
  border-radius 20px
  background-color #f8f8f8
  box-shadow 0 0 5px 2px #f8f8f8

  &:before
    width auto
    margin 0

  &:hover
    display block

.selector__glyph:hover .selector__glyph-delete
  display block

.selector__glyph-inner
  font-weight normal
  //border 2px solid transparent
  border-radius 30px
  position absolute
  width 100%
  height 100%
  margin 0
  overflow hidden

  font-size inherit
  text-align center

  // hack to not use border on main obj - that breaks
  // glyph aligment in webkit 
  &:before
    position absolute
    content ''
    display block
    left 0
    right 0
    top 0
    bottom 0
    border-radius 30px
    border 2px solid transparent

.selector__glyph-inner:hover, .selector__glyph:focus .selector__glyph-inner
  background #333
  color #fff

.selector__glyph.xselectable-selected

  .selector__glyph-inner
    background-color #eeffee
    &:before
      border-color #8d8
    &:hover
      color inherit

.selector__glyph
  .selector__glyph-inner
    &.checked
      background-color #fffdfd
      &:before
        border-color #d88
  &.xselectable-selected
    .selector__glyph-inner
      &.checked
        background-color #efe

.selector__glyph .selector__glyph-inner.checked:hover, .selector__glyph:focus .selector__glyph-inner.checked
  background #666

.selector__glyph.xselectable-selected .selector__glyph-inner.checkedbefore:border-color #666,
.selector__glyph.xselectable-selected .selector__glyph-inner.checked:hover
  color inherit



// Animation tweaks
//
// - 3 turns, to not abuse peoples
// - instant, on mouse hover
//
.selector__glyph .animate-spin
  animation spin 2s 3 linear
  display: inline-block

.selector__glyph:hover .animate-spin
    animation-iteration-count infinite


////////////////////////////////////////////////////////////////////////////////
// Misc

// Message, when nothing found
.selector__search-not-found
  display none
  font-size 14px
// temporary hack, to hide msg on slow chrome load
// remove when search results moved to separate pane
.search-mode .selector__search-not-found
  display block

// Help for custom icons block
.selector__custom-helper
  font-size 14px
  margin-bottom 30px
  padding 15px
  border-radius 3px
  background-color #f8f8f8
  text-align center


// Helper, shown after click on glyph,
// to indicate that multiselect possible
._multicursor
._multicursor .selector__glyph label
.xselectable-box
  cursor crosshair
